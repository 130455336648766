import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TrustPilot, Reviews } from '../layouts/TrustPilot';
import Container from '../layouts/Core/Container';
import Travel from '../layouts/Core/Travel';

import { useTawkContext } from '../layouts/TawkContext';

import MapDedicated from '../layouts/MapDedicated';
import Servers, { LOCATIONS, SORT_TAGS, STOCK_TAGS } from '../layouts/Servers';

import gslMark from '../assets/images/globalsecurelayer-mark.svg';

import ReactCountryFlag from "react-country-flag";

import ubuntu from '../assets/images/os-ubuntu.svg';
import ubuntuHover from '../assets/images/os-ubuntu-hover.svg';

import windows from '../assets/images/os-windows.svg';
import windowsHover from '../assets/images/os-windows-hover.svg';

import debian from '../assets/images/os-debian.svg';
import debianHover from '../assets/images/os-debian-hover.svg';

import almalinux from '../assets/images/os-almalinux.svg';
import almalinuxHover from '../assets/images/os-almalinux-hover.svg';

import rockylinux from '../assets/images/os-rockylinux.svg';
import rockylinuxHover from '../assets/images/os-rockylinux-hover.svg';

import ikvm from '../assets/images/os-ikvm.svg';
import ikvmHover from '../assets/images/os-ikvm-hover.svg';

import ddosShield from '../assets/images/ddos-shield.svg';

// Function to shuffle array for Reviews
function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default function DedicatedPage() {
    const { location: urlLocation } = useParams();
    const navigate = useNavigate();
    const [locationFilter, setLocationFilter] = useState(LOCATIONS.WORLDWIDE);
    const [sortFilter, setSortFilter] = useState(SORT_TAGS.ALL);
    const [stockFilter, setStockFilter] = useState(STOCK_TAGS.IN);
	const [randomReviews, setRandomReviews] = useState<Array<typeof Reviews[number]>>([]);

    const generateTitleAndDescription = (location) => {
        let title = 'Streamline Servers - Dedicated Servers';
        let description = 'No setup fees and powered by a low-latency anycast network with global protection!';

        if (location && location !== LOCATIONS.WORLDWIDE) {
            title = `Streamline Servers - ${location} Dedicated Servers`;
            description = `Explore our dedicated servers in ${location}, with global low-latency network protection.`;
        }

        return { title, description };
    };

    const formatLocationForUrl = (location) => {
        return location.toLowerCase().replace(/\s+/g, '-');
    };
    
    const reformatLocationFromUrl = (urlLocation) => {
        if (!urlLocation) return LOCATIONS.WORLDWIDE;
        return urlLocation
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
    };

    const formatLocationForDisplay = (location) => {
        return location.replace(/-/g, ' ').split(' ')
                       .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                       .join(' ');
    };    

    const handleLocationSelect = (location) => {
        const formattedLocation = formatLocationForUrl(location);
        setLocationFilter(formattedLocation);
        navigate(`/dedicated-servers/${formattedLocation}`);
    };
	
	const { handleMaximize } = useTawkContext();

    useEffect(() => {
        const { title, description } = generateTitleAndDescription(locationFilter);
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', description);
        }
    }, [locationFilter]);

    useEffect(() => {
        if (!urlLocation) {
            setLocationFilter(LOCATIONS.WORLDWIDE);
        } else {
            const formattedLocation = reformatLocationFromUrl(urlLocation);
            setLocationFilter(formattedLocation);
        }
    }, [urlLocation]);

    useEffect(() => {
        const formattedLocation = reformatLocationFromUrl(urlLocation);
        setLocationFilter(formattedLocation);
    }, [urlLocation]);

	useEffect(() => {
		const shuffledReviews = shuffleArray([...Reviews]);
		setRandomReviews(shuffledReviews);
	}, []);
    
    return (
        <Container title='Streamline Servers - Dedicated Servers' description='No setup fees and powered by a low-latency anycast network with global protection!' className='page-dedicated'>
            <section className="text-center sub-hero pb-5" id="hero-hosting">
                <div className="container">
                    <h1>Dedicated Servers</h1>
                    <p className="pb-2">
                    No setup fees and powered by a low-latency anycast network with global protection!
                    </p>
                    <div className="block text-center mt-5 font-14" id="dedicated-trustpilot">
                    <img src={TrustPilot.trustpilotStarsHome} className="block mx-auto mb-3 tp-stars" /> Rated <strong>{TrustPilot.TrustScore}</strong> out of <strong>5.0</strong> based on <a href={TrustPilot.URL}>{TrustPilot.Reviews} reviews</a>
                    </div>
                </div>
                </section>

                <section className="pt-0" id="dedicated-plans">
                <div className="container">
                    <div className="filter-row dedicated-filters pb-3 pt-3 ptfix">
                    <div className="row">
                        <div className="col col-sm-6 col-md-3 col-lg-3 m-order-1">
                        <span className="font-12 block opacity-50">Server location</span>
                        <div className="dropdown sik-dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                { (locationFilter === formatLocationForDisplay(LOCATIONS.WORLDWIDE) || locationFilter === "worldwide" || locationFilter === "") && <><span className="fa-regular fa-earth-americas mr-2"></span> Worldwide</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.SYDNEY.city) && <><ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Sydney</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.BRISBANE.city) && <><ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Brisbane</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.MELBOURNE.city) && <><ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Melbourne</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.PERTH.city) && <><ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Perth</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.ADELAIDE.city) && <><ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Adelaide</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.AUCKLAND.city) && <><ReactCountryFlag countryCode="NZ" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Auckland</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.LOS_ANGELES.city) && <><ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Los Angeles</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.NEW_YORK.city) && <><ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> New York</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.ASHBURN.city) && <><ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Ashburn</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.DALLAS.city) && <><ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Dallas</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.SEATTLE.city) && <><ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Seattle</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.MIAMI.city) && <><ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Miami</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.SINGAPORE.city) && <><ReactCountryFlag countryCode="SG" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Singapore</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.TOKYO.city) && <><ReactCountryFlag countryCode="JP" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Tokyo</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.HONG_KONG.city) && <><ReactCountryFlag countryCode="HK" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Hong Kong</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.AMSTERDAM.city) && <><ReactCountryFlag countryCode="NL" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Amsterdam</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.FRANKFURT.city) && <><ReactCountryFlag countryCode="DE" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Frankfurt</> }
								{ locationFilter === formatLocationForDisplay(LOCATIONS.PARIS.city) && <><ReactCountryFlag countryCode="FR" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Paris</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.MARSEILLE.city) && <><ReactCountryFlag countryCode="FR" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Marseille</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.LONDON.city) && <><ReactCountryFlag countryCode="GB" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> London</> }
                                { locationFilter === formatLocationForDisplay(LOCATIONS.WARSAW.city) && <><ReactCountryFlag countryCode="PL" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Warsaw</> }
								{ locationFilter === formatLocationForDisplay(LOCATIONS.MILAN.city) && <><ReactCountryFlag countryCode="IT" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Milan</> }
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-scroll">
                                <li className="filter-by-us" onClick={() => handleLocationSelect('')}>
                                    <span className="dropdown-item">
                                        <span className="fa-regular fa-earth-americas mr-2"></span> Worldwide
                                    </span>
                                </li>
                                <p className="ml-1 mt-2 font-12 bold">Australia</p>
                                <li className="filter-by-au" onClick={() => handleLocationSelect('sydney')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Sydney
                                    </span>
                                </li>
                                <li className="filter-by-au" onClick={() => handleLocationSelect('brisbane')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Brisbane
                                    </span>
                                </li>
                                <li className="filter-by-au" onClick={() => handleLocationSelect('melbourne')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Melbourne
                                    </span>
                                </li>
                                <li className="filter-by-au" onClick={() => handleLocationSelect('perth')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Perth
                                    </span>
                                </li>
                                <li className="filter-by-au" onClick={() => handleLocationSelect('adelaide')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Adelaide
                                    </span>
                                </li>
                                <p className="ml-1 mt-2 font-12 bold">New Zealand</p>
                                <li className="filter-by-au" onClick={() => handleLocationSelect('auckland')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="NZ" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Auckland
                                    </span>
                                </li>
                                <p className="ml-1 mt-2 font-12 bold">North America</p>
                                <li className="filter-by-us" onClick={() => handleLocationSelect('Los Angeles')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Los Angeles
                                    </span>
                                </li>
                                <li className="filter-by-us" onClick={() => handleLocationSelect('new york')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> New York
                                    </span>
                                </li>
                                <li className="filter-by-us" onClick={() => handleLocationSelect('Ashburn')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Ashburn
                                    </span>
                                </li>
                                <li className="filter-by-us" onClick={() => handleLocationSelect('Dallas')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Dallas
                                    </span>
                                </li>
                                <li className="filter-by-us" onClick={() => handleLocationSelect('Seattle')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Seattle
                                    </span>
                                </li>
                                <li className="filter-by-us" onClick={() => handleLocationSelect('Miami')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Miami
                                    </span>
                                </li>
                                <p className="ml-1 mt-2 font-12 bold">Asia</p>
                                <li className="filter-by-sg" onClick={() => handleLocationSelect('Singapore')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="SG" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Singapore
                                    </span>
                                </li>
                                <li className="filter-by-jp" onClick={() => handleLocationSelect('Tokyo')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="JP" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Tokyo
                                    </span>
                                </li>
                                <li className="filter-by-hk" onClick={() => handleLocationSelect('Hong Kong')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="HK" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Hong Kong
                                    </span>
                                </li>
                                <p className="ml-1 mt-2 font-12 bold">Europe</p>
                                <li className="filter-by-nl" onClick={() => handleLocationSelect('Amsterdam')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="NL" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Amsterdam
                                    </span>
                                </li>
                                <li className="filter-by-de" onClick={() => handleLocationSelect('Frankfurt')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="DE" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Frankfurt
                                    </span>
                                </li>
                                <li className="filter-by-fr" onClick={() => handleLocationSelect('Paris')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="FR" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Paris
                                    </span>
                                </li>
                                <li className="filter-by-fr" onClick={() => handleLocationSelect('Marseille')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="FR" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Marseille
                                    </span>
                                </li>
                                <li className="filter-by-gb" onClick={() => handleLocationSelect('London')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="GB" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> London
                                    </span>
                                </li>
                                <li className="filter-by-gb" onClick={() => handleLocationSelect('Warsaw')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="PL" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Warsaw
                                    </span>
                                </li>
                                <li className="filter-by-gb" onClick={() => handleLocationSelect('Milan')}>
                                    <span className="dropdown-item">
                                        <ReactCountryFlag countryCode="IT" className="va-top mr-2 mb-1" style={{ width: '1.2em', height: '1.2em' }} svg /> Milan
                                    </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div className="col  col-md-autocol-lg-6 text-center m-order-3">
                        <span className="font-12 block opacity-50">Showing servers</span>
                        <ul className="lined-tabs filter-lined-tabs mt-n1" id="nav-tab" role="tablist">
                            <li className="inline-block px-3"><a style={{cursor: 'pointer'}} onClick={function() {setStockFilter(STOCK_TAGS.BOTH)}} className={`filter-all ${stockFilter === STOCK_TAGS.BOTH ? 'active' : ''}`}>All Servers</a></li>
                            <li className="inline-block px-3"><a style={{cursor: 'pointer'}} onClick={function() {setStockFilter(STOCK_TAGS.IN)}} className={`filter-in-stock ${stockFilter === STOCK_TAGS.IN ? 'active' : ''}`}>In Stock</a></li>
                            <li className="inline-block px-3"><a style={{cursor: 'pointer'}} onClick={function() {setStockFilter(STOCK_TAGS.OUT)}} className={`filter-out-of-stock ${stockFilter === STOCK_TAGS.OUT ? 'active' : ''}`}>Out of Stock</a></li>
                        </ul>
                        </div>
                        <div className="col col-sm-6 col-md-3 col-lg-3 text-right m-order-2">
                        <div className="inline-block mr-4 ml-n5 mrfix mlfix reset-filters-fix">
                            <span className="reset-filters font-12 block opacity-50"> &nbsp;</span>
                            <span className="reset-filters inline-block label label-error mt-1" style={{display: 'none'}}><i className="fa-solid fa-xmark font-11 mr-1"></i>Reset Filters</span>
                        </div>
                        <div className="inline-block text-left pull-right">
                            <span className="font-12 block opacity-50">Filter servers by</span>
                            <div className="dropdown sik-dropdown" id="sik-select-filters">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {sortFilter === SORT_TAGS.ALL && 'All Servers'}
                                {sortFilter === SORT_TAGS.SALE && 'On-Sale Servers'}
								{sortFilter === SORT_TAGS.ENTRY && 'Entry Servers'}
								{sortFilter === SORT_TAGS.PERFORMANCE && 'Performance Servers'}
								{sortFilter === SORT_TAGS.ENTERPRISE && 'Enterprise Servers'}
								{sortFilter === SORT_TAGS.INTEL && 'Intel Servers'}
                                {sortFilter === SORT_TAGS.AMD_OR_EPYC && 'AMD Servers'}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li onClick={function() {setSortFilter(SORT_TAGS.ALL)}}>
                                    <span className="dropdown-item">
                                        All Servers
                                    </span>
                                </li>
                                <li onClick={function() {setSortFilter(SORT_TAGS.SALE)}}>
                                    <span className="dropdown-item">
                                        On-Sale Servers
                                    </span>
                                </li>
								<li onClick={function() {setSortFilter(SORT_TAGS.ENTRY)}}>
                                    <span className="dropdown-item">
                                        Entry Level Servers
                                    </span>
                                </li>
								<li onClick={function() {setSortFilter(SORT_TAGS.PERFORMANCE)}}>
                                    <span className="dropdown-item">
                                        Performance Servers
                                    </span>
                                </li>
								<li onClick={function() {setSortFilter(SORT_TAGS.ENTERPRISE)}}>
                                    <span className="dropdown-item">
                                        Enterprise Servers
                                    </span>
                                </li>
								<li onClick={function() {setSortFilter(SORT_TAGS.INTEL)}}>
                                    <span className="dropdown-item">
                                        Intel Servers
                                    </span>
                                </li>
                                <li onClick={function() {setSortFilter(SORT_TAGS.AMD_OR_EPYC)}}>
                                    <span className="dropdown-item">
                                        AMD Servers
                                    </span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <Servers locationTag={locationFilter} sortTag={sortFilter} stockTag={stockFilter}/>
                    <div className="block pt-5 text-center">
                        <strong className="block">Can't find what you are looking for?</strong>
                        Try another location or <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="bold">Contact us</a> to enquire.
                    </div>
                </div>
                </section>

                <section className="light-bg" id="dedicated-os">
                <div className="container">
                    <div className="title">
                    <h2><small>SERVER DISTRIBUTIONS</small>Available Operating Systems</h2>
                    <p>
                        We provide automatic one-click installers for all major operating systems
                    </p>
                    </div>
                    <div className="row text-center">
                        <div className="col col-md-3 col-lg-2">
                            <div className="box">
                            <img src={ubuntu} className="block mx-auto mb-3" />
                            <img src={ubuntuHover} className="block mx-auto mb-3" />
                            <strong className="font-18">Ubuntu</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-2">
                            <div className="box">
                            <img src={rockylinux} className="block mx-auto mb-3" />
                            <img src={rockylinuxHover} className="block mx-auto mb-3" />
                            <strong className="font-18">Rocky Linux</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-2">
                            <div className="box">
                            <img src={windows} className="block mx-auto mb-3" />
                            <img src={windowsHover} className="block mx-auto mb-3" />
                            <strong className="font-18">Windows</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-2">
                            <div className="box">
                            <img src={almalinux} className="block mx-auto mb-3" />
                            <img src={almalinuxHover} className="block mx-auto mb-3" />
                            <strong className="font-18">AlmaLinux</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-2">
                            <div className="box">
                            <img src={debian} className="block mx-auto mb-3" />
                            <img src={debianHover} className="block mx-auto mb-3" />
                            <strong className="font-18">Debian</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-2">
                            <div className="box">
                            <img src={ikvm} className="block mx-auto mb-3" />
                            <img src={ikvmHover} className="block mx-auto mb-3" />
                            <strong className="font-18">iKVM/IPMI</strong>
                            </div>
                        </div>
                    </div>
                </div>
                </section>


                <section id="locations-with-features">
                <div className="container">
                    <div className="title">
                    <h2><small>OUR LOCATIONS</small>Global Locations</h2>
                    <p>
                        Our servers are currently available in the following locations.
                    </p>
                    </div>
                    <div className="row mt-3 mtfix">
                    <div className="col col-lg-3 col-xl-3 mt-4 mtfix hidemobile hidetablet hidelg">
                        <div className="location-feature clearfix mt-4 pt-2">
                            <div className="icon-relative pull-left mr-4">
                            <i className="fa-solid fa-shield-check va-mid font-48 grad-behind-icon"></i>
                            <i className="fa-thin fa-shield-check va-mid font-48 green-icon"></i>
                            </div>
                        <strong className="block font-18 pull-left">Advanced <br />DDoS Protection</strong>
                        </div>
                        <div className="location-feature clearfix mt-4 pt-2">
                            <div className="icon-relative pull-left mr-4">
                            <i className="fa-solid fa-ethernet va-mid font-48 grad-behind-icon"></i>
                            <i className="fa-thin fa-ethernet va-mid font-48 green-icon"></i>
                            </div>
                        <strong className="block font-18 pull-left">20 Tbps <br />Network Capacity</strong>
                        </div>
                        <div className="location-feature clearfix mt-4 pt-2">
                            <div className="icon-relative pull-left mr-4">
                            <i className="fa-solid fa-circle-nodes va-mid font-48 grad-behind-icon"></i>
                            <i className="fa-thin fa-circle-nodes va-mid font-48 green-icon"></i>
                            </div>
                        <strong className="block font-18 pull-left">Low-Latency <br />Optimised Routing</strong>
                        </div>
                        <div className="location-feature clearfix mt-4 pt-2">
                            <div className="icon-relative pull-left mr-4">
                            <i className="fa-solid fa-globe va-mid font-40 grad-behind-icon"></i>
                            <i className="fa-thin fa-globe va-mid font-40 green-icon"></i>
                            </div>
                        <strong className="block font-18 pull-left">Global <br />Anycast Routing</strong>
                        </div>

                    </div>
                    <div className="col col-lg-12 col-xl-9">
                        <MapDedicated/>
                    </div>
                    </div>
                </div>
                </section>


                <section className="py-0" id="cta-gradient-box">
                <div className="container">
                    <div className="cta-gradient-box">
                    <div className="row">
                        <div className="col">
                        <h2><small>24/7/365 SUPPORT</small>Support you can count on!</h2>
                        <p>
                            Our team is always here to help. Whether you are stuck on installing a custom OS or have lost your admin password, we can help!
                        </p>
                        <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="button outline mt-1 mr-3">SUPPORT TICKET <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
                        <a href="javascript:void(0);" onClick={handleMaximize} className="button mt-1">Sales Live Chat</a>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="light-bg text-center" id="testimonial-slider">
                <div className="container">
                    <div id="carouselTestimonialsControls" className="carousel slide" data-bs-ride="carousel">
						<div className="carousel-inner">
						  {randomReviews.map((review, index) => (
							<div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
							  <p className="font-22 pb-2">
								<span className="font-30">"</span>{review.Review}<span className="font-30">"</span>
							  </p>
							  <img src={TrustPilot.trustpilotStarsSlider} className="block mx-auto mb-3"/>
							  <h4 className="font-22">{review.Name}</h4>
							  <span className="block font-14 opacity-50 mt-n1 mb-4">www.streamline-servers.com</span>
							  <a href={TrustPilot.URL} className="white-link font-14 bold">Read more reviews on <img src={TrustPilot.trustpilotLogoGreenSmall} className="va-mid ml-1" /></a>
							</div>
						  ))}
						</div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselTestimonialsControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselTestimonialsControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </section>


                <section className="py-5" id="ddos-row">
                <div className="container py-3">
                    <div className="row">
                    <div className="col col-lg-4 text-center">
                        <img src={ddosShield} className="mobile-icon-height" />
                    </div>
                    <div className="col col-lg-8 mtfix ptfix">
                        <h2 className="pt-5 ptfix"><small>INCLUDED DDOS PROTECTION</small>Make Sure Your Server is Always Online!</h2>
                        <p className="pt-1">
                        Streamline-Servers has formed a strategic partnership with Global Secure Layer, a renowned cybersecurity firm known for their latest DDoS technology. This collaboration empowers us to offer our clients robust protection against DDoS attacks by integrating Global Secure Layer's cutting-edge mitigation solution into our infrastructure. Together, we ensure uninterrupted availability and optimal performance of our clients' online services, leveraging advanced technologies to swiftly detect and mitigate malicious traffic. Through this partnership, Streamline-Servers reaffirms its commitment to delivering top-tier DDoS Protection solutions and providing clients with the peace of mind they deserve.
                        </p>
                        <strong className="font-18 block pb-1 pt-2">DDoS Protection by <img src={gslMark} className="ml-1 mr-1 gsl-mark-sm" />Global Secure Layer</strong>
                        <a href="https://globalsecurelayer.com/ddos-protection" className="strong">Find our more <i className="fa-sharp fa-solid fa-chevrons-right font-11"></i></a>
                    </div>
                    </div>
                </div>
                </section>


                <div className="container">
					<div className="line-gradient-divider"></div>
                </div>

            <section id="faq-single">
            <div className="container">
                <div className="title text-left">
                <h2><small>DEDICATED SERVER FAQ</small>Frequently Asked Questions</h2>
                </div>
                <div className="accordions" id="accordionFAQ">
                <div className="row">
                    <div className="col col-md-12 col-lg-6">
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Do you offer trial services?
                                        </button>
                                    </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            We provide trial services on a case-by-case basis, tailored to your specific needs and quantities. Contact us through our support channel to arrange your personalized trial today!
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Do you provide DDoS protection?
                                        </button>
                                    </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            Yes, our services come with comprehensive DDoS protection as a standard feature, thanks to our strategic partnership with Global Secure Layer.
							<br />
							In most instances, this level of protection is sufficient to safeguard your service from the majority of threats.
							<br />
							For those seeking more advanced security options, we offer the flexibility to acquire additional features at any time, granting real-time reporting, priority protection support and advanced self-management capabilities for your protection profiles.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What network equipment do you use / do you offer 10 Gbps services?
                                        </button>
                                    </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            At the core of our dedicated server network, we rely on Arista Network's cutting-edge switching and router equipment. To ensure high performance and consistency, we have standardized the use of 10 Gbps connections across all our dedicated servers. For our enterprise focused range of servers, we go even further, providing 40 Gbps uplinks to meet the demands of resource-intensive tasks. Although some of our older budget range servers still operate at 1 Gbps, we are swiftly phasing them out or upgrading them to maintain the highest standards of speed and efficiency throughout our infrastructure.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Do you offer overclockable servers?
                                        </button>
                                    </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            We provide overclockable servers backed with watercooling in specific locations. They feature state-of-the-art watercoolers with redundant pumps, ensuring both reliability and exceptional performance. With this specialized offering, we strike the perfect balance between delivering reliable services and meeting the demands of users seeking unparalleled performance capabilities.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Can I install my own operating system / what if I need to reinstall my operating system?
                                        </button>
                                    </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            <strong>Absolutely!</strong> We impose no restrictions on installing your preferred operating system. You can easily do this via remote management by simply mounting an ISO file. Additionally, for your convenience, we provide automatic one-click installers for all major operating systems through the dedicated servers panel. 
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingEleven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                            Do you provide servers with unmetered/unlimited bandwidth?
                                        </button>
                                    </h2>
                        <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            Yes, we offer such servers, but they are currently available only in select locations. You can find the add-on option during the checkout process. Please note that unmetered servers are subject to our strict fair use policy, which you can review by accessing a copy <Travel to="/legal#aup">here</Travel>.
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col col-md-12 col-lg-6">
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Do your servers include dedicated IPs and can I get more?
                                        </button>
                                    </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            With every server, you receive a dedicated /31 IPv4 and /64 IPv6 allocation as a standard offering. If you require additional IP addresses, our higher-end servers can accommodate an add-on of up to a /28 IPv4 allocation for an extra fee.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingSeven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            Can I BYO my own operating system license?
                                        </button>
                                    </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            <strong>Certainly!</strong> You are welcome to Bring Your Own (BYO) operating system license. During the checkout process, you can select a different operating system. Afterward, you can reinstall the server in trial mode from our panel and proceed to activate your own license for the chosen operating system.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingEight">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            What bandwidth choices do you provide?
                                        </button>
                                    </h2>
                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            Every package includes a specific monthly quota, which varies based on the location and server class. Our premium range typically offers more quota. If you wish to upgrade to a higher bandwidth package, you can reach out to our support team anytime. We also offer favorable discounts based on traffic profiles, and there are bandwidth pooling options available when multiple servers are purchased in the same region.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingNine">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                            Do you provide remote management to dedicated servers? (e.g. IPMI, LOM, iDRAC)
                                        </button>
                                    </h2>
                        <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                            Yes, all our servers come equipped with full remote management access. You can access it anytime through the dedicated servers panel, using a secure gateway server in each location.
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

                <section className="green-bg" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-6">
                        <h2>Deploy your Dedicated Server Today!</h2>
                        <strong className="block pb-4 font-22">Don't see a server you are looking for? Contact Us!</strong>
                        <strong className="font-18 block pb-1">You can contact us at anytime</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>Support Ticket</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i> Sales Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-lg-6 text-center">
                        <Travel to="/dedicated-servers" className="button large dark mt-5">Back to Top <i className="fa-sharp fa-solid fa-arrow-up-long ml-1"></i></Travel>
                    </div>
                    </div>
                </div>
                </section>
        </Container>
    );
}