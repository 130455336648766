import Server, { ServerProps } from "./Server";
import { useEffect, useState } from "react";
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';

import axios from 'axios';

export interface ServersProps {
    locationTag: string;
    sortTag: string;
    stockTag: string;
}

interface ServerApi {
	serverID: string;
    Processor: string;
	ProcessorModel: string;
	ProcessorCore: string;
	ProcessorSpeed: string;
    Memory: string;
	MemoryAmount: string;
	MemorySpeed: string;
    Storage: string;
	StorageAmount: string;
	StorageType: string;
    "Data Transfer": string;
    "Link Speed": string;
    Name: string;
    Quantity: number;
    ID: number;
    Price: string;
	NewPrice?: string;
    Location: string;
    Sale?: string;
	Labels?: string;
	outputLabels?: {
		className: string;
		label: string;
		icon: string;
    }[];

    // Used after processing
    Tags?: string[];
    ProcessorMake? : string;
	OldPrice?: string;
	Promo?: string;
};

export const STOCK_TAGS = {
    IN: "in",
    OUT: "out",
    BOTH: "both"
};

export const LOCATIONS = {
  WORLDWIDE: 'worldwide',

  SYDNEY: { city: 'sydney', country: 'AU' },
  BRISBANE: { city: 'brisbane', country: 'AU' },
  MELBOURNE: { city: 'melbourne', country: 'AU' },
  PERTH: { city: 'perth', country: 'AU' },
  ADELAIDE: { city: 'adelaide', country: 'AU' },

  AUCKLAND: { city: 'auckland', country: 'NZ' },

  LOS_ANGELES: { city: 'los angeles', country: 'US' },
  NEW_YORK: { city: 'new york', country: 'US' },
  CHICAGO: { city: 'chicago', country: 'US' },
  ASHBURN: { city: 'ashburn', country: 'US' },
  DALLAS: { city: 'dallas', country: 'US' },
  SEATTLE: { city: 'seattle', country: 'US' },
  MIAMI: { city: 'miami', country: 'US' },

  SINGAPORE: { city: 'singapore', country: 'SG' },
  TOKYO: { city: 'tokyo', country: 'JP' },
  HONG_KONG: { city: 'hong kong', country: 'HK' },

  AMSTERDAM: { city: 'amsterdam', country: 'NL' },
  FRANKFURT: { city: 'frankfurt', country: 'DE' },
  PARIS: { city: 'paris', country: 'FR' },
  MARSEILLE: { city: 'marseille', country: 'FR' },
  LONDON: { city: 'london', country: 'GB' },
  WARSAW: { city: 'warsaw', country: 'PL' },
  MILAN: { city: 'milan', country: 'IT' }
};

//WILL NEED TO CHANGE THESE SO JUST HAVE SALE, AMD, INTEL
export const SORT_TAGS = {
    ALL: '',
	NEW: 'new',
    SALE: 'sale',
	ENTRY: 'entry',
	GAMING: 'gaming',
	PERFORMANCE: 'performance',
	ENTERPRISE: 'enterprise',
    STORAGE: 'storage',
    AMD_OR_EPYC: 'amd',
	INTEL: 'intel'
};



export default function Servers( props: ServersProps ) {

    const [serverList, setServerList] = useState<ServerApi[] | null>(null);
	const { currencySign, currencyId  } = useSharedCurrencyState();

    useEffect(() => {
        // Run axios query
        axios({
            method: "GET",
            url: `https://billing.streamline-servers.com/custom_api/dedi_stock_api.php?currency=${currencyId}`
        }).then(res => {
            let candidates: ServerApi[] = [];

            // So here's the thing you need to remember - The filtering here won't work because there's no location on the returned data that matches existing filters. Fix that, then alter the filters, and you're golden.

            // First we can filter by location...
            if(props.locationTag === LOCATIONS.WORLDWIDE) {
                candidates = res.data;
            }
            else {
                res.data.forEach(function(server) {
                    if(server.Location.toUpperCase().includes(props.locationTag.toUpperCase())) {
                        candidates.push(server);
                    }
                });
            }

            // Next we can then filter by filterables...
            if(props.sortTag !== "" || props.stockTag !== "") {
                let filteredCandidates: ServerApi[] = [];

                candidates.forEach(function(server) {
                    // We need to decide the tags for this candidate...
                    let tags: string[] = [];

                    // Check if AMD or EPYC
                    if(server.Processor.toUpperCase().includes('RYZEN') || server.Processor.toUpperCase().includes('EPYC'))
                    {
                        server.ProcessorMake = server.Processor.toUpperCase().includes('RYZEN') ? 'ryzen' : 'epyc';
                        tags.push(SORT_TAGS.AMD_OR_EPYC.toUpperCase());
                    }
                    else {
                        server.ProcessorMake = 'intel';
						tags.push(SORT_TAGS.INTEL.toUpperCase());
                    }
					
					//Format CPU
					if (server.Processor !== undefined) {
					  const separators = ' - ';
					  const firstSeparatorIndex = server.Processor.indexOf(separators);
					  const secondSeparatorIndex = server.Processor.indexOf(separators, firstSeparatorIndex + separators.length);
					  
					  if (firstSeparatorIndex !== -1 && secondSeparatorIndex !== -1) {
						server.ProcessorModel = server.Processor.substring(0, firstSeparatorIndex);
						server.ProcessorCore = server.Processor.substring(firstSeparatorIndex + separators.length, secondSeparatorIndex);
						server.ProcessorSpeed = server.Processor.substring(secondSeparatorIndex + separators.length);
					  } else {
						server.ProcessorModel = server.Processor;
						server.ProcessorCore = '';
						server.ProcessorSpeed = '';
					  }
					}
					
					//Format RAM
					if (server.Memory !== undefined) {
						const memoryParts = server.Memory.split(' ');
						server.MemoryAmount = memoryParts[0] + ' ' + memoryParts[1];
						server.MemorySpeed = memoryParts.slice(2).join(' ');
					}
					
					//Format Storage
					if (server.Storage !== undefined) {
						const storageRegex = /(\d+\s*(?:TB|GB))/i;
						const storageMatch = server.Storage.match(storageRegex);

						if (storageMatch !== null) {
							const delimiterIndex = storageMatch.index !== undefined ? storageMatch.index + storageMatch[0].length : -1;
							if (delimiterIndex !== -1) {
								server.StorageAmount = server.Storage.substring(0, delimiterIndex).trim();
								server.StorageType = server.Storage.substring(delimiterIndex).trim();
							} else {
								server.StorageAmount = server.Storage;
								server.StorageType = '';
							}
						} else {
							server.StorageAmount = server.Storage;
							server.StorageType = '';
						}
					}

					//Get Link
					server.serverID = 'https://billing.streamline-servers.com/cart.php?a=add&pid=' + server.ID + '&currency=' + currencyId;
					
					if (server.Sale !== undefined) {
						const price = parseFloat(server.Price);
						const salePercentage = parseFloat(server.Sale.replace('%', '')) / 100;

						if (server.Promo) {
							server.OldPrice = server.Price;  // Keep the old price as the current list price
							const newPrice = price - (price * salePercentage);
							server.NewPrice = newPrice.toFixed(2);  // Store the new discounted price
							server.serverID += '&promocode=' + encodeURIComponent(server.Promo);
						} else {
							const oldPrice = price / (1 - salePercentage);
							server.OldPrice = oldPrice.toFixed(2);
							server.NewPrice = server.Price; // If no promo, new price is the same as the current price
						}
					}

                    // Check for labels
					if (server.Labels !== undefined) {
						server.outputLabels = [];

						if (server.Labels.includes('New')) {
							tags.push(SORT_TAGS.NEW.toUpperCase());
							server.outputLabels.push({
								className: "server-new",
								label: "New",
								icon: "fa-sparkles"
							});
						}
						
						if (server.Labels.includes('Performance')) {
							tags.push(SORT_TAGS.PERFORMANCE.toUpperCase());
							server.outputLabels.push({
								className: "server-info",
								label: "Performance",
								icon: "fa-gauge"
							});
						}
						
						if (server.Labels.includes('Storage')) {
							tags.push(SORT_TAGS.STORAGE.toUpperCase());
							server.outputLabels.push({
								className: "server-info",
								label: "Storage",
								icon: "fa-database"
							});
						}
						
						if (server.Labels.includes('Entry')) {
							tags.push(SORT_TAGS.ENTRY.toUpperCase());
							server.outputLabels.push({
								className: "server-info",
								label: "Entry Level",
								icon: "fa-hammer"
							});
						}
						
						if (server.Labels.includes('Gaming')) {
							tags.push(SORT_TAGS.GAMING.toUpperCase());
							server.outputLabels.push({
								className: "server-info",
								label: "Gaming",
								icon: "fa-gamepad"
							});
						}
						
						if (server.Labels.includes('Enterprise')) {
							tags.push(SORT_TAGS.ENTERPRISE.toUpperCase());
							server.outputLabels.push({
								className: "server-info",
								label: "Enterprise",
								icon: "fa-briefcase"
							});
						}

						if (server.Sale !== undefined) {
							tags.push(SORT_TAGS.SALE.toUpperCase());
							server.outputLabels.push({
								className: "server-on-sale",
								label: server.Sale + " OFF",
								icon: "fa-tag"
							});

							server.outputLabels.push({
								className: "server-stock",
								label: "Sale Ends " + server["Sale End"].toString(),
								icon: "fa-clock"
							});
						}
					}

                    server.Tags = tags;
                    
                    if(tags.includes(props.sortTag.toUpperCase()) || props.sortTag === SORT_TAGS.ALL) {
                        if(props.stockTag === STOCK_TAGS.BOTH) {
                            filteredCandidates.push(server);
                        }
                        else {
                            if(props.stockTag === STOCK_TAGS.IN && server.Quantity > 0) {
                                filteredCandidates.push(server);
                            }
                            else if(props.stockTag === STOCK_TAGS.OUT && server.Quantity === 0) {
                                filteredCandidates.push(server);
                            }
                        }
                    }
                })
		
                candidates = filteredCandidates;
            }

			const sortedServers = candidates.sort((a, b) => {
			  const isNewA = a.Labels?.includes('New') ?? false;
			  const isNewB = b.Labels?.includes('New') ?? false;
			  const isOnSaleA = a.Sale !== undefined;
			  const isOnSaleB = b.Sale !== undefined;

			  if (isOnSaleA && !isOnSaleB) {
				return -1; // a comes first if it has a sale and b doesn't
			  } else if (!isOnSaleA && isOnSaleB) {
				return 1; // b comes first if it has a sale and a doesn't
			  } else {
				if (isOnSaleA && isOnSaleB) {
				  // If both servers are on sale, sort by price in ascending order
				  return parseFloat(a.Price) - parseFloat(b.Price);
				} else if (isOnSaleA && !isOnSaleB) {
				  // a is on sale, but b is not, so a comes first
				  return -1;
				} else if (!isOnSaleA && isOnSaleB) {
				  // b is on sale, but a is not, so b comes first
				  return 1;
				} else {
				  // Both servers are not on sale, now check the "New" condition
				  if (isNewA && !isNewB) {
					return -1; // a comes first if it's New and b is not
				  } else if (!isNewA && isNewB) {
					return 1; // b comes first if it's New and a is not
				  } else {
					// Both servers are either New or not New, sort by price in ascending order
					return parseFloat(a.Price) - parseFloat(b.Price);
				  }
				}
			  }
			});

			setServerList(sortedServers);

        }).catch(err => {
            /* You decide what to do here if there's an error my captain*/
            setServerList(null);
        });
    }, [props, currencyId]);

    return (
        <div className="filtered-divs active">
            {
                serverList != null && serverList.map((server, index) => {
                    return <Server
                        key={`servers-${index}`}
                        locationTags={server.Location}
                        sortTags={server.Tags !== undefined && server.Tags.length !== 0 ? server.Tags.join(' ') : ''}
                        inStock={server.Quantity > 0}
						stockQTY={server.Quantity}
						serverLink={server.serverID}
                        name={server.Name}
                        processorManufacturer={server.ProcessorMake !== undefined ? server.ProcessorMake : ''}
                        processorText={[server.ProcessorModel, server.ProcessorCore, server.ProcessorSpeed]}
                        ramText={[server.MemoryAmount, server.MemorySpeed]}
                        storageText={[server.StorageAmount, server.StorageType]}
                        transferText={[server["Data Transfer"], '']}
						transferSpeed={[server["Link Speed"], '']}
                        monthlyPrice={`${currencySign}${server.NewPrice ?? server.Price}`}
						oldPrice={server.OldPrice ? `${currencySign}${server.OldPrice}` : ""}
                        labels={server.outputLabels}
                    />
                })
            }
        </div>
    );
}