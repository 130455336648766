import { useEffect } from 'react';
import * as $ from 'jquery';

import ReactCountryFlag from "react-country-flag";

interface IProps {}

const locationData = [
	{
		region: "North America",
		locations: [
			{ city: "Los Angeles", country: "USA", countryCode: "US", className: "location-la" },
			{ city: "New York", country: "USA", countryCode: "US", className: "location-ny" },
			{ city: "Ashburn", country: "USA", countryCode: "US", className: "location-ash" },
			{ city: "Miami", country: "USA", countryCode: "US", className: "location-mia" },
			{ city: "Dallas", country: "USA", countryCode: "US", className: "location-dal" },
			{ city: "Seattle", country: "USA", countryCode: "US", className: "location-sea" },
		]
	},
	{
		region: "Europe",
		locations: [
			{ city: "Amsterdam", country: "NL", countryCode: "NL", className: "location-ne" },
			{ city: "Frankfurt", country: "DE", countryCode: "DE", className: "location-gr" },
			{ city: "Paris", country: "FR", countryCode: "FR", className: "location-pr" },
			{ city: "Marseille", country: "FR", countryCode: "FR", className: "location-mrs" },
			{ city: "London", country: "GB", countryCode: "GB", className: "location-uk" },
			{ city: "Warsaw", country: "PL", countryCode: "PL", className: "location-pl" },
			{ city: "Milan", country: "IT", countryCode: "IT", className: "location-it" },
		]
	},
	{
		region: "Oceania",
		locations: [
			{ city: "Auckland", country: "NZ", countryCode: "NZ", className: "location-nz" },
			{ city: "Sydney", country: "AU", countryCode: "AU", className: "location-syd" },
			{ city: "Brisbane", country: "AU", countryCode: "AU", className: "location-bris" },
			{ city: "Melbourne", country: "AU", countryCode: "AU", className: "location-mel" },
			{ city: "Adelaide", country: "AU", countryCode: "AU", className: "location-adl" },
			{ city: "Perth", country: "AU", countryCode: "AU", className: "location-perth" },
			{ city: "Singapore", country: "SG", countryCode: "SG", className: "location-sg" },
			{ city: "Tokyo", country: "JP", countryCode: "JP", className: "location-jp" },
			{ city: "Hong Kong", country: "HK", countryCode: "HK", className: "location-hk" },
		]
	}
];

export default function MapDedicated( props: IProps ) {

    useEffect(() => {
        const locations = ['chi', 'sea', 'dal', 'la', 'fl', 'atl', 'ny', 'qc', 'ne', 'fr', 'gr', 'uk', 'it', 'nz', 'syd', 'bris', 'mel', 'adl', 'perth', 'sg', 'jp', 'hk', 'ash', 'mrs', 'mia', 'pr', 'pl'];

        function showLocationInfo(locationClass) {
            $(".pin-hover").hide();
            $(".pin.active").removeClass('active');
            $(`.${locationClass}-info`).show();
            $(".map-has-list ul li").removeClass('active');
            $(".map-has-list ul li." + locationClass).addClass('active');
            $('.' + locationClass).addClass('active');
        }

        locations.forEach(location => {
            $(`.location-${location}`).on('click mouseover', function () {
                showLocationInfo('location-' + location);
            });
        });

        $('.loc-list').on('click', function () {
            const locationClass = $(this).attr('class').split(' ').find(className => className.startsWith('location-'));
            if (locationClass) {
                showLocationInfo(locationClass);
            }
        });

		const hideBox = (event) => {
			if (!$(event.target).closest('.pin, .pin-hover, .loc-list').length) {
				$(".pin-hover").hide();
				$(".pin.active").removeClass('active');
				$(".map-has-list ul li").removeClass('active');
			}
		};

        $(document).on('click', hideBox);

        return () => {
            $(document).off('click', hideBox);
            $('.loc-list').off('click');
            locations.forEach(location => {
                $(`.location-${location}`).off('click');
            });
        };
    }, []);

    return (
        <>
		<div className="map">
		  {locationData.flatMap(region => region.locations).map(location => (
			<>
			  <div className={`pin ${location.className}`}></div>
			  <div className={`pin-hover ${location.className}-info`}>
				<strong>
				  <ReactCountryFlag 
					countryCode={location.countryCode}
					className="va-top mr-2"
					style={{width:'1.2em',height:'1.2em'}}
					svg
				  /> 
				  {location.city}
				</strong>
			  </div>
			</>
		  ))}
		</div>
        </>
    );
}
