import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { TawkProvider } from '../layouts/TawkContext';

// Generic Pages
import ErrorPage from '../pages/404';
import AboutPage from '../pages/AboutPage';
import AffiliatesPage from '../pages/AffiliatesPage';
import AllLocationsPage from '../pages/AllLocationsPage';
import CloudPage from '../pages/CloudPage';
import DedicatedPage from '../pages/DedicatedPage';
import GameServersPage from '../pages/GameServers';
import GameServersInnerPage from '../pages/GameServersInnerPage';
import IndexPage from '../pages/IndexPage';
import LegalPage from '../pages/LegalPage';
import LocationPage from '../pages/LocationPage';
import VoiceServersPage from '../pages/VoiceServersPage';
import WebHostingPage from '../pages/WebHostingPage';
import MyIP from '../pages/MyIP';

//Events
//import Christmas from '../pages/Events/Christmas24';


export const STANDARD_ROUTE_URIS = {
    ABOUT: '/about',
    AFFILIATES: '/affiliates',
    ALL_LOCATIONS: '/all-locations',
    CLOUD: '/cloud-gaming-servers',
    DEDICATED: '/dedicated-servers',
    GAME_SERVERS: '/game-servers',
    GAME_SERVERS_INNER: '/game-servers/:game',
    INDEX: '/',
    LEGAL: '/legal',
    LOCATION: '/location',
    VOICE_SERVERS: '/voice-servers',
    WEB_HOSTING: '/web-hosting',
	MyIP: '/myip',
	
	//Events
	//Christmas: '/christmas'
}

const RedirectRoute: React.FC<{ path: string; to: string }> = ({ path, to }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith(path)) {
      // Extract the query string from the current URL
      const queryString = location.search;

      // Construct the new URL with the query string appended to the specified domain
      const newPath = to + location.pathname.substring(path.length) + queryString;

      // Preserve the subdomain by checking if the current URL has a subdomain
      const url = new URL(window.location.href);
      if (url.hostname.startsWith('old.')) {
        const oldSubdomain = url.hostname.split('.')[0];
        const newHostname = to.split('//')[1]; // Remove the protocol
        const newUrl = `https://${oldSubdomain}.${newHostname}${location.pathname}${queryString}`;
        window.location.replace(newUrl);
      } else {
        // Redirect to the new domain with the query string preserved
        window.location.replace(newPath);
      }
    }
  }, [location, path, to]);

  return null;
};

export default function Router() {

    return (
		<TawkProvider>
				<Routes>
				{/* Add the new custom Route for redirecting old links to the billing subdomain */}
				<Route
					path="/billing/*"
					element={<RedirectRoute path="/billing" to="https://billing.streamline-servers.com" />}
				/>
			
				<Route key='route-00' path={STANDARD_ROUTE_URIS.ABOUT} element={<AboutPage/>} />,
				<Route key='route-01' path={STANDARD_ROUTE_URIS.AFFILIATES} element={<AffiliatesPage/>} />,
				<Route key='route-02' path={STANDARD_ROUTE_URIS.ALL_LOCATIONS} element={<AllLocationsPage/>} />,
				<Route key='route-03' path={STANDARD_ROUTE_URIS.CLOUD} element={<CloudPage/>} />,
				<Route key='route-04' path={STANDARD_ROUTE_URIS.DEDICATED} element={<DedicatedPage/>} />,
				<Route key='route-04-location' path={`${STANDARD_ROUTE_URIS.DEDICATED}/:location`} element={<DedicatedPage/>} />,
				<Route key='route-05' path={STANDARD_ROUTE_URIS.GAME_SERVERS} element={<GameServersPage/>} />,
				<Route key='route-06' path={STANDARD_ROUTE_URIS.GAME_SERVERS_INNER} element={<GameServersInnerPage/>} />,
				<Route key='route-07' path={STANDARD_ROUTE_URIS.INDEX} element={<IndexPage/>} />,
				<Route key='route-08' path={STANDARD_ROUTE_URIS.LEGAL} element={<LegalPage/>} />,
				<Route key='route-09' path={STANDARD_ROUTE_URIS.LOCATION} element={<LocationPage/>} />,
				<Route key='route-10' path={STANDARD_ROUTE_URIS.VOICE_SERVERS} element={<VoiceServersPage/>} />,
				<Route key='route-11' path={STANDARD_ROUTE_URIS.WEB_HOSTING} element={<WebHostingPage/>} />,
				<Route key='route-12' path={STANDARD_ROUTE_URIS.MyIP} element={<MyIP/>} />,
				
				{/*
				EVENTS
				
				<Route key='route-13' path={STANDARD_ROUTE_URIS.Christmas} element={<Christmas/>} />,
				*/}
				
				{/* Old links redirect for SEO */}
				<Route path="/legal/privacy-policy.pdf" element={<Navigate to="/legal#pp" />} />
				<Route path="/legal/unmetered-aup-dedicated.pdf" element={<Navigate to="/legal#aup" />} />
				
				{/* These are handled by AWS now but kept here as a backup */}
				<Route path="/audedicatedservers.php" element={<Navigate to="/dedicated-servers/sydney" />} />
				<Route path="/usdedicatedservers.php" element={<Navigate to="/dedicated-servers/los-angeles" />} />
				<Route path="/nzdedicated.php" element={<Navigate to="/dedicated-servers/auckland" />} />
				<Route path="/europe-dedicated.php" element={<Navigate to="/dedicated-servers/frankfurt" />} />
				<Route path="/singaporededicated.php" element={<Navigate to="/dedicated-servers/singapore" />} />
				<Route path="/tokyo-dedicated.php" element={<Navigate to="/dedicated-servers/tokyo" />} />
				<Route path="/hongkong-dedicated.php" element={<Navigate to="/dedicated-servers/hong-kong" />} />
				<Route path="/dedicated-backup-solutions.php" element={<Navigate to="/dedicated-servers" />} />
				
				<Route path="/network/sydney.php" element={<Navigate to="/dedicated-servers/sydney" />} />
				<Route path="/network/melbourne.php" element={<Navigate to="/dedicated-servers/melbourne" />} />
				<Route path="/network/perth.php" element={<Navigate to="/dedicated-servers/perth" />} />
				<Route path="/network/dallas.php" element={<Navigate to="/dedicated-servers/dallas" />} />
				<Route path="/vps/perth.php" element={<Navigate to="/dedicated-servers/perth" />} />
				<Route path="/network/brisbane.php" element={<Navigate to="/dedicated-servers/brisbane" />} />
				<Route path="/network/auckland.php" element={<Navigate to="/dedicated-servers/auckland" />} />
				<Route path="/network/chicago.php" element={<Navigate to="/dedicated-servers/chicago" />} />

				<Route path="/auvpshosting.php" element={<Navigate to="/dedicated-servers/sydney" />} />
				<Route path="/lavpshosting.php" element={<Navigate to="/dedicated-servers/los-angeles" />} />
				<Route path="/nzvpshosting.php" element={<Navigate to="/dedicated-servers/auckland" />} />
				<Route path="/sgvpshosting.php" element={<Navigate to="/dedicated-servers/singapore" />} />
				<Route path="/gaming-vds.php" element={<Navigate to="/cloud-gaming-servers" />} />

				<Route path="/auwebhosting.php" element={<Navigate to="/web-hosting" />} />
				<Route path="/uswebhosting.php" element={<Navigate to="/web-hosting" />} />

				<Route path="/affiliate.php" element={<Navigate to="/affiliates" />} />

				<Route path="/about.php" element={<Navigate to="/about" />} />
				<Route path="/index.php" element={<Navigate to="/" />} />
				<Route path="/404.php" element={<Navigate to="/404" />} />

				<Route path="/tos.php" element={<Navigate to="/legal#tos" />} />
				
				<Route path="/giveaways.php" element={<Navigate to="/" />} />

				<Route path="/voiceservers/mumble.php" element={<Navigate to="/voice-servers" />} />
				<Route path="/voiceservers/teamspeak-3.php" element={<Navigate to="/voice-servers" />} />

				<Route path="/gameservers/high-performance-game-servers.php" element={<Navigate to="/game-servers" />} />
				<Route path="/gameservers/hpgsplus.php" element={<Navigate to="/game-servers" />} />
				
				<Route path="/gameservers.php" element={<Navigate to="/game-servers" />} />
				<Route path="/gameservers/7daystodie.php" element={<Navigate to="/game-servers/7-days-to-die" />} />
				<Route path="/gameservers/ark-survival-evolved.php" element={<Navigate to="/game-servers/ark" />} />
				<Route path="/gameservers/reactivedrop.php" element={<Navigate to="/game-servers/alien-swarm-reactive-drop" />} />
				<Route path="/gameservers/arma2-epoch.php" element={<Navigate to="/game-servers/arma2-epoch" />} />
				<Route path="/gameservers/arma2-oa.php" element={<Navigate to="/game-servers/arma2-operation-arrowhead" />} />
				<Route path="/gameservers/arma3-headless-client.php" element={<Navigate to="/game-servers/arma3-headless" />} />
				<Route path="/gameservers/arma3.php" element={<Navigate to="/game-servers/arma3" />} />
				<Route path="/gameservers/arma-reforger.php" element={<Navigate to="/game-servers/arma-reforger" />} />
				<Route path="/gameservers/assetto.php" element={<Navigate to="/game-servers/assetto-corsa" />} />
				<Route path="/gameservers/atlas.php" element={<Navigate to="/game-servers/atlas" />} />
				<Route path="/gameservers/avorion.php" element={<Navigate to="/game-servers/avorion" />} />
				<Route path="/gameservers/battalion-1944.php" element={<Navigate to="/game-servers/battalion-1944" />} />
				<Route path="/gameservers/blackmesa.php" element={<Navigate to="/game-servers/black-mesa" />} />
				<Route path="/gameservers/blackwake.php" element={<Navigate to="/game-servers/blackwake" />} />
				<Route path="/gameservers/cod4.php" element={<Navigate to="/game-servers/call-of-duty-4-modern-warfare" />} />
				<Route path="/gameservers/cod-mw3.php" element={<Navigate to="/game-servers/call-of-duty-modern-warfare-3" />} />
				<Route path="/gameservers/chiv.php" element={<Navigate to="/game-servers/chivalry-medieval-warfare" />} />
				<Route path="/gameservers/citadel.php" element={<Navigate to="/game-servers/citadel-forged-with-fire" />} />
				<Route path="/gameservers/conan-exiles.php" element={<Navigate to="/game-servers/conan-exiles" />} />
				<Route path="/gameservers/contagion.php" element={<Navigate to="/game-servers/contagion" />} />
				<Route path="/gameservers/csgo.php" element={<Navigate to="/game-servers/counter-strike-global-offensive" />} />
				<Route path="/gameservers/cs1.6.php" element={<Navigate to="/game-servers/counter-strike-1-6" />} />
				<Route path="/gameservers/csgo-war.php" element={<Navigate to="/game-servers/counter-strike-go-war-server" />} />
				<Route path="/gameservers/cssource.php" element={<Navigate to="/game-servers/counter-strike-source" />} />
				<Route path="/gameservers/dark-and-light.php" element={<Navigate to="/game-servers/dark-and-light" />} />
				<Route path="/gameservers/dod.php" element={<Navigate to="/game-servers/day-of-defeat-1-3" />} />
				<Route path="/gameservers/dods.php" element={<Navigate to="/game-servers/day-of-defeat-source" />} />
				<Route path="/gameservers/day-of-infamy.php" element={<Navigate to="/game-servers/day-of-infamy" />} />
				<Route path="/gameservers/dayz.php" element={<Navigate to="/game-servers/dayz" />} />
				<Route path="/gameservers/days-of-war.php" element={<Navigate to="/game-servers/days-of-war" />} />
				<Route path="/gameservers/dontstarve.php" element={<Navigate to="/game-servers/dont-starve-together" />} />
				<Route path="/gameservers/dab.php" element={<Navigate to="/game-servers/double-action-boogaloo" />} />
				<Route path="/gameservers/eco.php" element={<Navigate to="/game-servers/eco" />} />
				<Route path="/gameservers/empyrion.php" element={<Navigate to="/game-servers/empyrion-galactic-survival" />} />
				<Route path="/gameservers/fortress.php" element={<Navigate to="/game-servers/fortress-forever" />} />
				<Route path="/gameservers/garrysmodprem.php" element={<Navigate to="/game-servers/garrys-mod-premium" />} />
				<Route path="/gameservers/garrysmodstan.php" element={<Navigate to="/game-servers/garrys-mod-standard" />} />
				<Route path="/gameservers/ground-branch.php" element={<Navigate to="/game-servers/ground-branch" />} />
				<Route path="/gameservers/heat.php" element={<Navigate to="/game-servers/heat" />} />
				<Route path="/gameservers/hell-let-loose.php" element={<Navigate to="/game-servers/hell-let-loose" />} />
				<Route path="/gameservers/hellion.php" element={<Navigate to="/game-servers/hellion" />} />
				<Route path="/gameservers/holdfast-nations-at-war.php" element={<Navigate to="/game-servers/holdfast-nations-at-war" />} />
				<Route path="/gameservers/hurtworld.php" element={<Navigate to="/game-servers/hurtworld" />} />
				<Route path="/gameservers/icarus.php" element={<Navigate to="/game-servers/icarus" />} />
				<Route path="/gameservers/insurgency.php" element={<Navigate to="/game-servers/insurgency-2014" />} />
				<Route path="/gameservers/insurgency-sandstorm.php" element={<Navigate to="/game-servers/insurgency-sandstorm" />} />
				<Route path="/gameservers/jc2.php" element={<Navigate to="/game-servers/just-cause-2" />} />
				<Route path="/gameservers/killingfloor.php" element={<Navigate to="/game-servers/killing-floor" />} />
				<Route path="/gameservers/killingfloor2.php" element={<Navigate to="/game-servers/killing-floor-2" />} />
				<Route path="/gameservers/l4d.php" element={<Navigate to="/game-servers/left-4-dead" />} />
				<Route path="/gameservers/l4d2.php" element={<Navigate to="/game-servers/left-4-dead-2" />} />
				<Route path="/gameservers/life.php" element={<Navigate to="/game-servers/multi-theft-auto" />} />
				<Route path="/gameservers/memories-of-mars.php" element={<Navigate to="/game-servers/memories-of-mars" />} />
				<Route path="/gameservers/minecraft.php" element={<Navigate to="/game-servers/minecraft" />} />
				<Route path="/gameservers/miscreated.php" element={<Navigate to="/game-servers/miscreated" />} />
				<Route path="/gameservers/mordhau.php" element={<Navigate to="/game-servers/mordhau" />} />
				<Route path="/gameservers/mta.php" element={<Navigate to="/game-servers/multi-theft-auto" />} />
				<Route path="/gameservers/naturalselection2.php" element={<Navigate to="/game-servers/natural-selection-2" />} />
				<Route path="/gameservers/nomore.php" element={<Navigate to="/game-servers/no-more-room-in-hell" />} />
				<Route path="/gameservers/no-one-survived.php" element={<Navigate to="/game-servers/no-one-survived" />} />
				<Route path="/gameservers/onset.php" element={<Navigate to="/game-servers/onset" />} />
				<Route path="/gameservers/orion-prelude.php" element={<Navigate to="/game-servers/orion-prelude" />} />
				<Route path="/gameservers/osiris-new-dawn.php" element={<Navigate to="/game-servers/osiris-new-dawn" />} />
				<Route path="/gameservers/outlaws-of-the-old-west.php" element={<Navigate to="/game-servers/outlaws-of-the-old-west" />} />
				<Route path="/gameservers/pixark.php" element={<Navigate to="/game-servers/pixark" />} />
				<Route path="/gameservers/post-scriptum.php" element={<Navigate to="/game-servers/post-scriptum" />} />
				<Route path="/gameservers/project-argo.php" element={<Navigate to="/game-servers/project-argo" />} />
				<Route path="/gameservers/project-zomboid.php" element={<Navigate to="/game-servers/project-zomboid" />} />
				<Route path="/gameservers/rage-mp.php" element={<Navigate to="/game-servers/rage-mp" />} />
				<Route path="/gameservers/rust.php" element={<Navigate to="/game-servers/rust" />} />
				<Route path="/gameservers/reflexfps.php" element={<Navigate to="/game-servers/reflexfps" />} />
				<Route path="/gameservers/reign-of-kings.php" element={<Navigate to="/game-servers/reign-of-kings" />} />
				<Route path="/gameservers/rend.php" element={<Navigate to="/game-servers/rend" />} />
				<Route path="/gameservers/rs2.php" element={<Navigate to="/game-servers/rising-storm-2" />} />
				<Route path="/gameservers/snbox.php" element={<Navigate to="/game-servers/s-n-box" />} />
				<Route path="/gameservers/samp.php" element={<Navigate to="/game-servers/samp" />} />
				<Route path="/gameservers/satisfactory.php" element={<Navigate to="/game-servers/satisfactory" />} />
				<Route path="/gameservers/skyrim.php" element={<Navigate to="/game-servers/skyrim-together-reborn" />} />
				<Route path="/gameservers/sniper-elite-4.php" element={<Navigate to="/game-servers/sniper-elite-4" />} />
				<Route path="/gameservers/sons-of-the-forest.php" element={<Navigate to="/game-servers/sons-of-the-forest" />} />
				<Route path="/gameservers/space.php" element={<Navigate to="/game-servers/space-engineers" />} />
				<Route path="/gameservers/squad.php" element={<Navigate to="/game-servers/squad" />} />
				<Route path="/gameservers/starbound.php" element={<Navigate to="/game-servers/starbound" />} />
				<Route path="/gameservers/stationeers.php" element={<Navigate to="/game-servers/stationeers" />} />
				<Route path="/gameservers/supraball.php" element={<Navigate to="/game-servers/supraball" />} />
				<Route path="/gameservers/synergy.php" element={<Navigate to="/game-servers/synergy" />} />
				<Route path="/gameservers/tf2.php" element={<Navigate to="/game-servers/team-fortress-2" />} />
				<Route path="/gameservers/tfclassic.php" element={<Navigate to="/game-servers/team-fortress-classic" />} />
				<Route path="/gameservers/terraria.php" element={<Navigate to="/game-servers/terraria" />} />
				<Route path="/gameservers/the-black-death.php" element={<Navigate to="/game-servers/the-black-death" />} />
				<Route path="/gameservers/the-forest.php" element={<Navigate to="/game-servers/the-forest" />} />
				<Route path="/gameservers/the-isle.php" element={<Navigate to="/game-servers/the-isle" />} />
				<Route path="/gameservers/tower-unite.php" element={<Navigate to="/game-servers/tower-unite" />} />
				<Route path="/gameservers/unturned.php" element={<Navigate to="/game-servers/unturned" />} />
				<Route path="/gameservers/v-rising.php" element={<Navigate to="/game-servers/v-rising" />} />
				<Route path="/gameservers/valheim.php" element={<Navigate to="/game-servers/valheim" />} />
				<Route path="/gameservers/ylands.php" element={<Navigate to="/game-servers/ylands" />} />
				
				{/* Catch-all route for 404 page */}
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</TawkProvider>
    );
}